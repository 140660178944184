import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      cookiepolicy: "This website uses cookies to enhance the user experience.",
      "meer informatie": "More information",
      "Lees meer": "Read more",
      overOnsZin: "Creates furniture with and for people.",
      intro:
        "Christl Dekimpe designs flexible, unique and tailor-made furniture for epic design enthusiasts. Christl grew up with design and love for interiors, and develops new, unique collections every year with respect for the needs and tastes of different individuals. Individuals who are looking for beauty, flexibility, the right price-quality ratio, a good finish and excellent customer service. This Belgian creative company, which values these values very highly, proudly carries the 'Handmade in Belgium' label. ",
      introProjects:
        "need creative advice for your project/horeca? Christl organizes brainstorm sessions with the client on request. We start with a list of basic requirements and cooperate, on location, on a unique concept completely tailored to your specific business / taste. An unlimited moment of creativity, brought back in a world of craftsmanship and material knowledge.  ",
      Projects: "Projects",
      Interior: "Interior",
      Home: "Home",
      "Over ons": "About",
      Collecties: "Collections",
      Nieuws: "News",
      Contact: "Contact",
      tafels: "Tabels",
      stoelen: "Chairs",
      barstoelen: "Barstools",
      loungezetels: "Lounges",
      Destinea: "Destinea",
      kasten: "Closets",
      salontafels: "Coffeetables",
      sofas: "Sofas",
      eettafels: "Dining tables",
      "Eettafels-outdoor": "Dining tables",
      "Salontafels-outdoor": "Coffeetables",
      "Stoelen-outdoor": "Chairs",
      "Tafels-outdoor": "Bistro bar tables",
      Onze: "Our",
      hib: "Handmade in Belgium",
      "Iets gezien dat je leuk vindt": "Saw something you like?",
      BIA: "Belgium Inovation and craft",
      KA: "When something defines the soul of your living room, this is undoubtedly what makes the space unique.",
      KAP: "The bar... where meetings begin, friendships are born and success is celebrated!",
      KATI: "Destinea is a creation of Christl Dekimpe, consisting of unique (side) tables! A traditional process in which solely natural materials are used ensures an exclusive and original result for each prospective buyer. Each line is characterized by an expressive name. An identity for the carefully selected colors and intensity of the work, which undoubtedly adds a unique look & feel to your interior. Destinea stands for local quality and is always provided with a numbered 'Made in Belgium' label.",
      KATP: "A personalised bar top for your restaurant, bar or mancave? It is possible with Destinea. By selecting the right (filling) materials, Christl creates a Destinea top that typifies your soul, interior. These are carefully integrated during the production process, ensuring a striking and unique result again and again.",
      Lokaal: "Local",
      LokaalT:
        "All our Destinea tables are carefully manufactured in our own workshops in Dadizele. Our team closely monitors every stage of this traditional production process in order to guarantee the best quality.",
      Eco: "Mother nature",
      EcoT: "As a company, we consciously choose to do business in a sustainable way, while carefully balancing our commitment to the environment, the social aspect and our business objective. We opted for ecologically sound materials and completely geared our production to these. We also prioritise these values in the office.",
      Soc: "With people for people",
      SocT: "The preparation of our production process was outsourced to VZW de Bloempit to demonstrate our social commitment.  We warmly support them and are especially grateful for the good work they do for us on a daily basis.",
      BI: "Made in Belgium",
      BIT: "Destinea was awarded the 'Made in Belgium' label. Let's be proud together.",
      Ontdek: "Discover the products here",
      Toepassingen: "Some realisations",
      "Jouw creatie": "Make your own creation?",
      "Contacteer ons": "Contact us",
      "Meer over": "More about",
      Wens: "Would you like to know more about our collections?",
      Contactpage: "Contact us",
      zincontact:
        "Would you like to know more about our collections? Send us your message by using the contact form below and we will get back to you as soon as possible. ",
      België: "Belgium",
      volgons: "Follow us",
      naam: "Name",
      telefoon: "Phone",
      Bericht: "Message",
      messageContact: "Your message has been sent, thanks for reaching out",
      Loading: "We are preparing the website",
      notfound:
        "It seems you are lost. We are unable to reach te page you were looking for.",
      homepageBack: "Go back to the homepage",
      "ontdek onze": "Discover our",
      overOnsPage:
        "Christl Dekimpe designs flexible, unique and tailor-made furniture for epic design enthusiasts. The company was founded in 1967 by her parents Albert and Erna Dekimpe. Everything started with exclusive Italian furniture which is still part of her collection today. Christl grew up with design and love for interiors, and develops new, unique collections every year with respect for the needs and tastes of different individuals. Individuals who are looking for beauty, flexibility, the right price-quality ratio, a good finish and excellent customer service. This Belgian creative company, which values these values very highly, proudly carries the 'Handmade in Belgium' label.",
      overOnsPageTwo:
        "Family is her main focus, her 2 sons Danthé ('04) and Odré ('06) keep her spirit vibrant young, which is definitely demonstrated in her innovative, handmade collections. The entire production is not only created in Belgium, but is also carried out in her workshop in Dadizele. Obviously Christl is not the only person behind this fantastic story. Every day she can count on a fantastic team, not to mention the unconditional support of her parents. Our secret? Is our passion! When you have fun, there are no limits! Her 2 sons, Danthé and Odré, did not prevent her from developing an innovative, handmade interior design brand, which is produced on a global scale. And... because this wonderful story is the life's work of a family, Christl would like to thank her parents, two sons and an ever enthusiastic team for their constant support.",
      SeeThePicture: "YOU SEE THE PICTURE",
      GaTerugCollecties: "Go back to the overview",
      creatiefAdvies: "Creative advice",
      "barstoelen Projects": "Bistro bar tables",
      algemenevoorwaarden: "Terms and conditions of sale",
      voorwaarde1:
        "The following conditions apply to the services provided by our company services and/or deliveries of goods, regardless of any conditions of the buyer.",
      voorwaarde2:
        "All complaints regarding the content of the invoices must be made in writing to us. submitted within five (5) days of the invoice date.",
      voorwaarde3:
        "All invoices are payable in cash. Any other method of payment accepted by us can never novation, nor modification or suppression of our general terms and conditions.",
      voorwaarde4:
        "Invoices not settled on their due date, are by operation of law and without notice of default subject, from their due date, to an interest rate of fifteen percent (15%).",
      voorwaarde5:
        "In addition, the amount of any invoice not paid in full on the due date of ipso jure and without notice of default increased with a fixed compensation equal to at twenty percent (20%) of the amount due with a minimum of one hundred and twenty-five euros (€ 125), The amount thus increased shall, ipso jure and without notice of default, generate interest equal to fifteen percent (15%).",
      voorwaarde6:
        "On delivery of goods, these remain the property of the seller until full payment has been received of it.",
      voorwaarde7:
        "This agreement' is subject to Belgian law. For all disputes relating to this agreement, the courts of Kortrijk shall have exclusive jurisdiction. The nullity of one clause, or part of a clause, shall not invalidate the others general terms and conditions of sale.",
      cookieprivacypolicy: "Privacy & Cookie policy",
      submit: "Send",
      openingsuren: "Opening hours",
      matodo: "Monday to Thursday",
      vrijdag: "Friday",
      fiche: "download the product file",
    },
  },
  fr: {
    translation: {
      cookiepolicy:
        "Ce site utilise des cookies pour améliorer l'expérience de navigation et fournir des fonctionnalités supplémentaires.",
      "meer informatie": "En savoir plus",
      "Lees meer": "Lire la suite",
      overOnsZin: "Creates furniture with and for people.",
      intro:
        "Christl Dekimpe crée des meubles flexibles, uniques, fabriqués sur mesure pour l’amateur de design épique. Plongée dans le design et l’amour de l’aménagement intérieur dès son plus jeune âge, Christl développe tous les ans des nouvelles collections uniques dans le respect des besoins et des goûts de différentes personnes. Des personnes qui sont à la recherche de beauté, de flexibilité, du bon rapport qualité-prix, d’une bonne finition et d’un excellent service pour le client. En accordant une grande importance à ces valeurs, cette créatrice belge porte avec fierté le label “Handmade in Belgium”.",
      introProjects:
        "vous avez besoin de conseils créatifs pour votre projet/horeca? Sur demande, Christl organise des séances de brainstorming avec le client. Nous commençons avec une liste d’exigences de base et nous élaborons ensemble, sur site, un concept unique totalement adapté à votre établissement/vos goûts. Un moment infini de créativité, ramené dans un monde de savoir-faire et de connaissances des matériaux.",
      Projects: "Projects",
      Interior: "Interior",
      Home: "Home",
      "Over ons": "À propos de nous",
      Collecties: "Collections",
      Nieuws: "Nouveautés",
      Contact: "Contact",
      tafels: "Tables",
      stoelen: "Chaises",
      barstoelen: "Chaises de bar",
      Loungezetel: "Fauteuils",
      loungezetels: "Fauteuils de salon",
      Destinea: "Destinea",
      kasten: "Armoires",
      salontafels: "Tables de salon",
      sofas: "Canapés",
      eettafels: "Tables à manger",
      "Eettafels-outdoor": "Tables à manger",
      "Salontafels-outdoor": "Tables de salon",
      "Stoelen-outdoor": "Chaises",
      "Tafels-outdoor": "Tables de bar Bistro",
      Onze: "Notre",
      hib: "Handmade in Belgium",
      "Iets gezien dat je leuk vindt":
        "Vous voulez en savoir plus sur nos collections?",
      BIA: "Innovation et artisanat belge",
      KA: "L’élément qui détermine l’âme de votre salle de séjour est sans aucun doute ce qui la rend unique.",
      KAP: "Le bar… c’est là où les réunions commencent, où les amitiés naissent, où on trinque au succès!",
      KATI: "Destinea est une création de Christl Dekimpe et toutes les tables (d’appoint) qui la composent sont uniques! Un processus artisanal utilisant exclusivement des matériaux naturels garantit un résultat exclusif et original pour chaque personne intéressée. Chaque ligne est caractérisée par un nom éloquent. Une identité pour les couleurs choisies minutieusement et l’intensité du travail, qui confère indubitablement un look & feel unique à votre intérieur. Destinea est synonyme de qualité locale et est toujours livré avec un certificat numéroté 'Made in Belgium'.",
      KATP: "Un plateau de bar personnalisé pour votre restaurant, votre bar ou votre tanière? C’est possible avec Destinea. En sélectionnant les bons matériaux (de rembourrage), Christl crée un plateau Destinea qui caractérise votre âme, votre intérieur. Ils sont intégrés minutieusement pendant le processus de production et garantissent à chaque fois un résultat remarquable et unique.",
      Lokaal: "Local",
      LokaalT:
        "Toutes nos tables Destinea sont fabriquées avec soin dans nos propres ateliers à Dadizele. Notre équipe suit de très près chaque phase de ce processus de production artisanal, afin de pouvoir ainsi garantir la meilleure qualité.",
      Eco: "Mère nature",
      EcoT: "En tant qu’entreprise, nous choisissons consciemment d’entreprendre de manière durable et nous maintenons dans ce cadre soigneusement l’équilibre entre l’attention pour l’environnement, l’aspect social et notre objectif d’entreprise. Nous avons recherché des matériaux écologiquement responsables et y avons totalement adapté notre production. Au bureau aussi, ces valeurs sont mises en avant.",
      Soc: "Avec les personnes, pour les personnes",
      SocT: "La préparation de notre processus de production a été sous-traitée à l’asbl de Bloempit en signe de notre engagement social. Nous les soutenons et nous leur sommes surtout reconnaissants pour le travail de qualité qu’ils fournissent quotidiennement pour nous.",
      BI: "Made in Belgium",
      BIT: "Destinea a été couronné du label “Made in Belgium”. Let’s be proud together.",
      Ontdek: "Découvrez-les ici",
      Toepassingen: "Quelques réalisations",
      "Jouw creatie": "Fabriquer votre propre création ?",
      "Contacteer ons": "Contactez-nous",
      "Meer over": "En savoir plus sur",
      Wens: "Vous voulez en savoir plus sur nos collections?",
      Contactpage: "Contactez-nous",
      zincontact:
        "Vous voulez en savoir plus sur nos collections? Envoyez-nous votre message via le formulaire de contact ci-dessous et nous reviendrons vite vers vous",
      België: "Belgique",
      volgons: "Suivez-nous",
      naam: "Nom",
      telefoon: "Téléphone",
      Bericht: "Message",
      messageContact: "Votre message a été envoyé, merci de prendre contact.",
      Loading: "We are preparing the website",
      notfound:
        "Il semblerait que vous êtes perdu. Nous ne parvenons pas à trouver la page que vous recherchez.",
      homepageBack: "Go back to the homepage",
      "ontdek onze": "Découvrir notre",
      overOnsPage:
        "Christl Dekimpe crée des meubles flexibles, uniques, fabriqués sur mesure pour l’amateur de design épique. L’entreprise a été fondée en 1967 par ses parents Albert et Erna Dekimpe. Tout a commencé avec des meubles italiens exclusifs qui font aujourd’hui encore partie de sa collection. Plongée dans le design et l’amour de l’aménagement intérieur dès son plus jeune âge, Christl développe tous les ans des nouvelles collections uniques dans le respect des besoins et des goûts de différentes personnes. Des personnes qui sont à la recherche de beauté, de flexibilité, du bon rapport qualité-prix, d’une bonne finition et d’un excellent service pour le client. En accordant une grande importance à ces valeurs, cette créatrice belge porte avec fierté le label “Handmade in Belgium”.",
      overOnsPageTwo:
        "La famille occupe une place centrale pour elle, ses 2 fils Danthé (‘04) et Odré (‘06) font en sorte qu’elle garde un cœur bouillonnant de jeunesse, ce qui s’exprime certainement dans ses collections innovatrices fabriquées à la main. Ses meubles ne sont pas seulement créés en Belgique, mais toute la production se déroule également dans son atelier de Dadizele. Christl n’est évidemment pas seule pour écrire cette histoire fantastique. Elle peut compter quotidiennement sur une équipe formidable, sans oublier le soutien inconditionnel de ses parents. Our secret? Is our passion! When you have fun, there are no limits! Ses 2 fils Danthé et Odré n’ont pas constitué un obstacle à son ambition de développer une marque d’intérieur innovatrice, fabriquée à la main et produite localement. Et… parce que cette histoire magnifique est l’œuvre d’une famille, Christl veut remercier ses parents, ses deux fils et son équipe toujours enthousiaste pour leur soutien permanent.",
      SeeThePicture: "YOU SEE THE PICTURE",
      GaTerugCollecties: "Rentrez au collections",
      creatiefAdvies: "Conseils créatifs",
      "barstoelen Projects": "Tables de bar Bistro",
      algemenevoorwaarden: "Conditions de vente",
      voorwaarde1:
        "Les présentes conditions s'appliquent aux services rendues et/ou aux livraisons des biens faites par notre société, quelles que soient Ies éventuelles conditions du bénéficiaire de ces services.",
      voorwaarde2:
        "Toute plainte concernant Ie contenu des factures doit nous être communiquée par écrit dans Ies cinq (5) jours après Ia date de facture.",
      voorwaarde3:
        "Toutes les factures sont payables au comptant. Tout autre mode de paiement accepté par notre entreprise n'impliquera jamais une novation, une modification ou une annulation de nos conditions générales",
      voorwaarde4:
        "Les factures qui sont impayés à l'échéance, donneront lieu, de plein droit et sans mise en demeure, à un intérêt de quinze pour cent (15 %) à partir du jour de l'échéance.",
      voorwaarde5:
        "En outre, Ie montant de chaque facture qui n'est pas intégralement réglée à I ‘échéance, sera majoré, de plein droit et sans mise en demeure, d'une indemnité forfaitaire de vingt pour cent (20 %) du montant dû avec un minimum de cent vingt-cinq euros (€ 125). Le montant majoré donnera lieu de plein droit et sans mise en demeure à un intérêt de quinze pour cent (15 %).",
      voorwaarde6:
        "La propriété de la marchandise vendue ne passe à l'acquéreur qu'après paiement intégral",
      voorwaarde7:
        "Cette convention est régie par Ie droit belge. Pour tous litiges concernant Ie présent accord, les tribunaux de Kortrijk (Courtrai) sont seuls compétents. La nullité d'une clause, ou d'une partie d'une clause, n'implique pas la nullité des autres conditions générales de vente",
      cookieprivacypolicy: "Politique en matière de vie privée & de cookies",
      submit: "envoyez",
      openingsuren: "Heures d'ouverture",
      matodo: "Du lundi au jeudi",
      vrijdag: "Vendredi",
      fiche: "Decouvrez la fiche",
    },
  },
  nl: {
    translation: {
      cookiepolicy:
        " Deze website gebruikt cookies voor een betere gebruikservaring.",
      "meer informatie": "Meer informatie",
      overOnsZin: "Creates furniture with and for people.",
      "Lees meer": "Lees meer",
      intro:
        "Christl Dekimpe creëert flexibele, unieke en tailor- made meubelen voor de epische designlover. Opgegroeid met design en liefde voor interieur, ontwikkelt Christl elk jaar nieuwe, unieke collecties met respect voor de behoeftes en smaken van verschillende individuen. Individuen die op zoek zijn naar schoonheid, flexibiliteit, de juiste prijs-kwaliteit, een goede afwerking en een uitstekende dienstverlening naar de klant.  Met deze waarden hoog in het vaandel draagt deze Belgische creativiteit met trots het “Handmade in Belgium”label.",
      introProjects:
        "Creatief advies nodig voor uw project/horeca? Op aanvraag organiseert Christl brainstormsessies met de klant. We starten met een lijst van basisvereisten en werken samen, op locatie, een uniek concept uit volledig afgestemd op jouw zaak/smaak. Een onbegrensd moment van creativiteit, teruggebracht in een wereld van vakmanschap en materiaalkennis.",
      Projects: "Projects",
      Interior: "Interior",
      Home: "Home",
      "Over ons": "Over ons",
      Collecties: "Collecties",
      Nieuws: "Nieuws",
      Contact: "Contact",
      tafels: "Tafels",
      stoelen: "Stoelen",
      barstoelen: "Barstoelen",
      loungezetels: "Loungezetels",
      Destinea: "Destinea",
      kasten: "Kasten",
      salontafels: "Salontafels",
      sofas: "Sofa's",
      eettafels: "Eettafels",
      "Eettafels-outdoor": "Eettafels",
      "Salontafels-outdoor": "Salontafels",
      "Stoelen-outdoor": "Stoelen",
      "Tafels-outdoor": "Bistro Bar tafels",
      Onze: "Onze",
      hib: "Handmade in Belgium",
      "Iets gezien dat je leuk vindt": "Iets gezien dat je leuk vindt?",
      BIA: "Belgische Innovatie & ambacht",
      KA: "Als iets de ziel van je woonkamer bepaalt, is het ongetwijfeld datgene wat de kamer uniek maakt.",
      KAP: "De bar… daar waar het samenkomen begint, vriendschappen ontstaan, geklonken wordt op succes! ",
      KATI: "Destinea is een Creatie van Christl Dekimpe en zijn stuk voor stuk unieke (bijzet)tafels! Een ambachtelijk proces waarbij uitsluitend natuurlijke materialen worden gebruikt, garandeert voor elke gegadigde, een exclusief en origineel resultaat. Iedere lijn wordt getypeerd door een sprekende naam. Een identiteit voor de zorgvuldig gekozen kleuren en intensiteit van het werk, die zonder twijfel een unieke look & feel bezorgt aan uw interieur. Destinea staat voor lokale kwaliteit en wordt steeds geleverd met een genummerd certificaat 'Made in Belgium'.",
      KATP: "Een gepersonaliseerd barblad voor uw restaurant, bar of mancave? Het kan met Destinea. Christl creeërt door selectie van de juiste (vul)materialen een Destinea-blad die uw ziel, interieur typeert. Deze worden tijdens het productieproces zorgvuldig geintegreerd en zorgen zo keer op keer voor een spraakmakend en uniek resultaat.",
      Lokaal: "Lokaal",
      LokaalT:
        "Al onze Destinea tafels worden met zorg vervaardigd in onze eigen ateliers in Dadizele. Ons team volgt iedere fase van dit ambachtelijk productieproces heel nauwlettend op, om zo de beste kwaliteit te kunnen garanderen. ",
      Eco: "Moeder natuur",
      EcoT: "Als bedrijf kiezen wij er bewust om duurzaam te ondernemen waarbij aandacht voor het milieu, het sociaal aspect en onze bedrijfsdoelstelling keurig in balans worden gehouden. We zochten naar ecologisch verantwoorde materialen en stelden onze productie hier volledig op af. Ook op kantoor worden deze waarden vooropgesteld.",
      Soc: "Met mensen - voor mensen",
      SocT: "De voorbereiding van ons productieproces werd uitbesteed aan VZW Mariënstede als teken van ons sociaal engagement. We dragen hen een warm hart toe en zijn hen vooral dankbaar voor het goede werk die ze dagelijks voor ons leveren. ",
      BI: "Made in Belgium",
      BIT: "Destinea werd bekroond met het label 'Made in Belgium'. Let’s be proud together. ",
      Ontdek: "Ontdek ze hier",
      Toepassingen: "Enkele realisaties",
      "Jouw creatie": "Jouw eigen creatie maken?",
      "Contacteer ons": "Neem contact op",
      "Meer over": "Meer over",
      Wens: "Wens je graag meer te weten over onze collecties?",
      Contactpage: "Contacteer ons",
      zincontact: "Meer weten over onze collecties?",
      België: "België",
      volgons: "Volg ons",
      naam: "Naam",
      telefoon: "Telefoon",
      Bericht: "Bericht",
      messageContact: "uw bericht is verzonden, bedankt om contact op te nemen",
      Loading: "De website is bezig met inladen",
      notfound:
        "Het lijkt dat je verdwaald bent. We kunnen de pagina niet vinden waar je naar op zoek bent.",
      homepageBack: "Ga naar de homepage",
      "ontdek onze": "Ontdek onze",
      overOnsPage:
        "Christl Dekimpe creëert flexibele, unieke en tailor- made meubelen voor de epische designlover. Het bedrijf werd in 1967 opgericht door haar ouders Albert en Erna Dekimpe.  Het startte allemaal met exclusieve Italiaanse meubelen die tot op vandaag nog altijd deel uit maken van haar collectie. Opgegroeid met design en liefde voor interieur, ontwikkelt Christl elk jaar nieuwe, unieke collecties met respect voor de behoeftes en smaken van verschillende individuen. Individuen die op zoek zijn naar schoonheid, flexibiliteit, de juiste prijs-kwaliteit, een goede afwerking en een uitstekende dienstverlening naar de klant.  Met deze waarden hoog in het vaandel draagt deze Belgische creativiteit met trots het “Handmade in Belgium”label",
      overOnsPageTwo:
        "Familie staat voor haar centraal, haar 2 zonen Danthé (‘04) en Odré (‘06) houden haar hart bruisend jong, wat zeker tot uiting komt in haar innovatieve, handgemaakte collecties. Niet alleen gecreëerd in België maar ook de volledige productie gaat door in haar atelier in Dadizele. Dit fantastisch verhaal schrijft Christl natuurlijk niet alleen. Dagelijks mag ze rekenen op een fantastisch team en niet te vergeten de onvoorwaardelijke steun van haar ouders. Our secret? Is our passion! When you have fun, there are no limits!",
      SeeThePicture: "YOU SEE THE PICTURE",
      GaTerugCollecties: "Terug naar overzicht",
      creatiefAdvies: "Creatief advies",
      "barstoelen Projects": "Bistro-bartafels",
      algemenevoorwaarden: "Verkoopsvoorwaarden",
      voorwaarde1:
        "Onderhavige voorwaarden zijn van toepassing op de door onze vennootschap gepresteerde diensten en/of leveringen van goederen, ongeacht eventuele voorwaarden van de koper.",
      voorwaarde2:
        "Alle klachten met betrekking tot de inhoud van de facturen moeten schriftelijk bij ons worden ingediend binnen de vijf (5) dagen na factuurdatum.",
      voorwaarde3:
        "Alle facturen zijn contant betaalbaar. Elke andere door ons aanvaarde betalingswijze kan nooit novatie, noch wijziging of afschaffing van onze algemene voorwaarden 'inhouden.",
      voorwaarde4:
        "De facturen niet vereffend op hun vervaldag, zijn van rechtswege en zonder ingebrekestelling onderhevig, vanaf hun vervaldag aan een interest ten belope van vijftien procent (15 %)",
      voorwaarde5:
        "Bovendien wordt het bedrag van elke factuur dat niet volledig betaald is op de vervaldag van rechtswege en zonder ingebrekestelling verhoogd met een forfaitaire schadevergoeding gelijk aan twintig procent (20 %) van het verschuldigde bedrag met een minimum van honderd vijfentwintig euro (€ 125), Het aldus verhoogde bedrag brengt van rechtswege en zonder ingebrekestelling een interest voort gelijk aan vijftien procent (15 %).",
      voorwaarde6:
        "Bij levering van goederen blijven deze eigendom van de verkoper tot de volledige betaling ervan.",
      voorwaarde7:
        "Deze overeenkomst' is onderworpen aan het Belgisch Recht. Voor alle geschillen met betrekking tot de onderhavige overeenkomst zijn uitsluitend de rechtbanken van Kortrijk bevoegd. De nietigheid van één clausule, of een gedeelte ervan, brengt geen nietigheid mee aan de overige algemene verkoopsvoorwaarden.",
      cookieprivacypolicy: "Privacy & Cookie policy",
      submit: "verstuur",
      openingsuren: "Openingsuren",
      matodo: "Maandag tot donderdag",
      vrijdag: "Vrijdag",
      fiche: "download de fiche",
    },
  },
};

i18n.use(initReactI18next).init({
  // we init with resources
  resources,
  fallbackLng: "nl",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});

export default i18n;
