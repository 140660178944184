import React from "react";
import '../css/index.css';
import ReactLoading from 'react-loading';

const LoadingScreen = () => {
    return(
        <section className="text-center homePage flexProjects">
            <p className="blackColor bodyCopy text-center">De website is bezig met inladen</p>
            <ReactLoading type="spin" color="#191919" className="text-center"/>
        </section>
    )
};

export default LoadingScreen;
