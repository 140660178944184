import React, { Fragment } from 'react';

import {AiOutlineCaretDown} from 'react-icons/ai';

class SiteSelector extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        showMenu: false,
        keuze: this.props.keuze,
        navColor: props.navColor,

      }

      this.showMenu = this.showMenu.bind(this);
      this.closeMenu = this.closeMenu.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.keuze !== this.props.keuze){
            this.setState({keuze: this.props.keuze});
        }

        if(prevProps.navColor !== this.props.navColor) {
          this.setState({navColor: this.props.navColor});
        }
    }

    showMenu(event) {
        event.preventDefault();
        
        this.setState({ showMenu: true }, () => {
          document.addEventListener('click', this.closeMenu);
        });
      }
      
      closeMenu() {
        this.setState({ showMenu: false }, () => {
          document.removeEventListener('click', this.closeMenu);
        });
      }

      setNavColor = () => {
        if(this.state.navColor === "black") {
          this.props.setNavColor("white");
        }
      }

    render() {
        const {keuze, navColor} = this.state;
        const {page} = this.props;
      return (
        <Fragment>
          <button onClick={(e) => {this.showMenu(e);}} className={`flexSelect  poppinsLight ${navColor === "black" || page === "/Destinea" ? "white" : "blackColor"}`}>
          {keuze} <AiOutlineCaretDown className="marginRightArrow"></AiOutlineCaretDown> 
        </button>
          
        {
          this.state.showMenu
            ? (
              <div className="menu">
                <button style={{cursor: 'pointer'}} value="interior" className={`siteSelector bodyCopy poppinsLight hoverElementDropDown ${keuze === "interior" ? "hidden" : ""}`} onClick={(e) => {this.props.onMadeChoise('interior'); this.setNavColor()}}> Interior </button>
                <button style={{cursor: 'pointer'}} value="projects"className={`siteSelector bodyCopy poppinsLight hoverElementDropDown ${keuze === "projects" ? "hidden" : ""}`}  onClick={(e) => {this.props.onMadeChoise('projects'); this.setNavColor()}}> Projects </button>
                <button style={{cursor: 'pointer'}} value="outdoor" className={`siteSelector bodyCopy poppinsLight hoverElementDropDown ${keuze === "outdoor" ? "hidden" : ""}`}  onClick={(e) => {this.props.onMadeChoise('outdoor'); this.setNavColor()}}> Outdoor </button>
              </div>
            )
            : (
              null
            )
        }
        </Fragment>
      );
    }
  }

export default SiteSelector;