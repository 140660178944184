import React, { PureComponent, Fragment } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import { withTranslation } from 'react-i18next';
import logoHIB from '../assets/hib.lightbox.png';
import logoFlanders from '../assets/Flanders_verticaal.png'
import {FaRegCopyright} from 'react-icons/fa';
import {FaHeart} from 'react-icons/fa';

class Footer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            keuze: props.keuze, 
            color: ''
        }
    }

    componentDidMount() {
        let tijdelijkeKeuze = sessionStorage.getItem('keuze');
        this.setState({ keuze: tijdelijkeKeuze });
      }

      componentDidUpdate(prevProps) {

        if (prevProps.keuze !== this.props.keuze) {
          this.setState({keuze: this.props.keuze});
        }
      }

      handleClickInterior = value => {
        this.props.setCollectie(value);
        this.props.history.push("/Collecties");
    }

    handleNavColor = () => {
        this.props.setNavColor('black');
    }

    handleWhiteColor = () => {
        this.props.setNavColor('white');
    }

    renderSwitch(keuze, t) {
        switch(keuze) {
            case "interior":
                return <Fragment>
                <h4 className="footerHeading poppinsMedium">{t("Collecties")}</h4>
                <ul className="textAlignLeft poppinsMedium bodyCopy">
                <li className="linksMarginBottom" onClick={() =>  { this.handleClickInterior(2); this.handleWhiteColor();}}>
                      {t("barstoelen")}
                      </li>
                 
                  
                      {/* <NavLink to="/Destinea" className="linksMarginBottom white links" onClick={() => {this.handleNavColor();}}>
                      <li className="linksMarginBottom">{t("Destinea")} <span className="tradeMarkHeader poppins poppinsLight ">&reg;</span></li>
                      </NavLink> */}
                
                  
                      <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(4); this.handleWhiteColor();}}>
                      {t("eettafels")}
                      </li>
                  
                 
                      <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(5); this.handleWhiteColor();}}>
                      {t("kasten")}
                      </li>
                
                  
                      <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(6); this.handleWhiteColor();}}>
                      {t("loungezetels")}
                      </li>
                 
                      <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(7); this.handleWhiteColor();}}>
                      {t("salontafels")}
                      </li>
                 
                      <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(8); this.handleWhiteColor();}}>
                      {t("sofas")}
                      </li>
                  
                 
                      <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(9); this.handleWhiteColor();}}>
                      {t("stoelen")}
                      </li>

           </ul>
               </Fragment>;
               case "projects":
                   return <Fragment>
                   <h4 className="footerHeading poppinsMedium">{t("Collecties")}</h4>
                   <ul className="textAlignLeft poppinsMedium bodyCopy">
                   <Link to="/Barstoelen" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("barstoelen")}
                    </li>
                       </Link> 
                       <Link to="/Tafels" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("barstoelen Projects")}
                    </li>
                       </Link> 
                       {/* <NavLink to="/Destinea" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleNavColor();}}>
                   {t("Destinea")}  <span className="tradeMarkHeader poppins poppinsLight">&reg;</span>
                    </li>
                       </NavLink>  */}
                       <Link to="/Loungezetel" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("loungezetels")}
                    </li>
                       </Link> 
                       
                       <Link to="/Stoelen" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("stoelen")}
                    </li>
                       </Link> 
                   </ul></Fragment>;
                case "outdoor":
                    return<Fragment>
                    <h4 className="footerHeading poppinsMedium">{t("Collecties")}</h4>
                    <ul className="textAlignLeft poppinsMedium bodyCopy">
                    <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(124); this.handleWhiteColor();}}>
                          {t("barstoelen Projects")}
                          </li>
                    
                    <li className="linksMarginBottom" onClick={() => { this.handleClickInterior(121); this.handleWhiteColor();}}>
                          {t("Eettafels-outdoor")}
                          </li>
                     
                          <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(122); this.handleWhiteColor();}}>
                          {t("Salontafels-outdoor")}
                          </li>
                          <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(123); this.handleWhiteColor();}}>
                          {t("Stoelen-outdoor")}
                          </li>
               </ul>
                   </Fragment>;
                   default: 
                   return <Fragment>
                   <h4 className="footerHeading poppinsMedium">{t("Collecties")} </h4>
                   <ul className="textAlignLeft poppinsMedium bodyCopy">
                   <li className="linksMarginBottom" onClick={() => { this.handleClickInterior(2); this.handleWhiteColor();}}>
                         {t("barstoelen")}
                         </li>
                    
                     
                         {/* <NavLink to="/Destinea" className="linksMarginBottom" onClick={() => {this.handleNavColor();}}>
                        <li>{t("Destinea")} <span className="tradeMarkHeader poppins poppinsLight">&reg;</span></li> 
                         </NavLink> */}
                   
                     
                         <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(4); this.handleWhiteColor();}}>
                         {t("eettafels")}
                         </li>
                     
                    
                         <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(5); this.handleWhiteColor();}}>
                         {t("kasten")}
                         </li>
                   
                     
                         <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(6); this.handleWhiteColor();}}>
                         {t("loungezetels")}
                         </li>
                    
                         <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(7); this.handleWhiteColor();}}>
                         {t("salontafels")}
                         </li>
                    
                         <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(8);this.handleWhiteColor();}}>
                         {t("sofas")}
                         </li>
                     
                    
                         <li className="linksMarginBottom" onClick={() => {this.handleClickInterior(9);this.handleWhiteColor();}}>
                         {t("stoelen")}
                         </li>
   
              </ul>
                  </Fragment>;
        }
    }

    render = () => {
        const {keuze} = this.state;
        const { t } = this.props;
        return (
            <footer className="footer white">
                <div className="footer footer-update">
                <div className="flexFooterImages">
                <img src={logo} alt="" width="4202" height="867" className="logoFooter"/>
                <img src={logoHIB} alt="logo Handmade in Belgium" className="logoFooter updateLogoHeader" width="375" height="700"/>
                </div>
                
                <section className="navFooter">
                <h4 className="footerHeading poppinsMedium">Menu</h4>

                {keuze === "interior" || keuze === "outdoor" ? <ul className="textAlignLeft poppinsMedium bodyCopy">
                        {/* <li className="linksMarginBottom">{t("Projects")}</li>  */}
                   <Link to="/Home" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Home")}
                    </li>
                       </Link> 
                       <Link to="/Overons" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Over ons")}
                    </li>
                       </Link> 
                       <Link to="/Collecties" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}> 
                   {t("Collecties")}
                    </li>
                       </Link> 
                       <Link to="/nieuws" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Nieuws")}
                    </li>
                       </Link> 
                       <Link to="/Contact" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Contact")}
                    </li>
                       </Link> 
                    </ul>
                    : <ul className="textAlignLeft poppinsMedium bodyCopy">
                         {/* <li className="linksMarginBottom">{t("Interior")}</li>  */}
                        <Link to="/Home" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Home")}
                    </li>
                       </Link> 
                       <Link to="/Overons" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Over ons")}
                    </li>
                       </Link> 
                       <Link to="/creatiefAdvies" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}> 
                   {t("creatiefAdvies")}
                    </li>
                       </Link> 
                       <Link to="/nieuws" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Nieuws")}
                    </li>
                       </Link> 
                       <Link to="/Contact" className="links white">
                   <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>
                   {t("Contact")}
                    </li>
                       </Link> 
                        </ul>}
                </section>

                <section className="navFooter">
                    {this.renderSwitch(keuze, t)}
                </section>
               

            <section className="navFooter poppinsMedium">
                <h4 className="footerHeading">{t("openingsuren")}</h4>
                <ul className="textAlignLeft bodyCopy">
                    <li className="linksMarginBottom" style={{fontSize: '14px', fontWeight: '700', marginTop: '15px'}}>{t("matodo")}:</li>
                    <li className="linksMarginBottom" style={{fontSize: '14px'}}>8:00 - 12:00 </li>
                    <li className="linksMarginBottom" style={{fontSize: '14px'}}>13:00 - 17:00</li><br />
                    <li className="linksMarginBottom" style={{fontSize: '14px', fontWeight: '700'}}>{t("vrijdag")}:</li>
                    <li className="linksMarginBottom" style={{fontSize: '14px'}}>8:00 - 12:00 </li>
                    <li className="linksMarginBottom" style={{fontSize: '14px'}}>13:00 - 15:00</li>

                </ul><br />
                <h4 className="footerHeading">Links</h4>
                <ul className="textAlignLeft bodyCopy">
                    <Link to="/AlgemeneVoorwaarden" className="links white"><li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>{t("algemenevoorwaarden")}</li></Link>
                   <Link to="/cookieprivacypolicy" className="links white" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'start' })}> <li className="linksMarginBottom" onClick={() => {this.handleWhiteColor();}}>{t("cookieprivacypolicy")}</li></Link>
                    <br/>
                    <span>With the support of</span><br/>
                    <img src={logoFlanders} style={{maxWidth: '75px'}} alt="logoFlanders"/>
                </ul>
            </section>
                </div>
               
            <div className="white bodyCopy poppinsLight">
                <FaRegCopyright></FaRegCopyright> {new Date().getFullYear()} | Made with <FaHeart></FaHeart> by <a href="https://thinkedge.be" target="_blank" rel="noopener noreferrer" className="link white">Thinkedge</a>
            </div>
            </footer>
            
        )
    }
};

export default withTranslation()(Footer);