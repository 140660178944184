import React from 'react';

class LanguageSelector extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            extended: false,
            lng: this.props.taal,
        }
    }

    handleChange = event => {
        this.props.handleTaal(event.target.value);
      };

    render = () => {
        const {lng} = this.state;
        return (
            <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
            <label>
              <select value={lng} className="links  taalSelector">
                <option value="nl">NL</option>
                <option value="en">EN</option>
                <option value="fr">FR</option>
              </select>
            </label>
          </form>
        );
    }
};

export default LanguageSelector;