import React, { PureComponent } from 'react';

class Policy extends PureComponent {

        componentDidMount() {
                window.scrollTo(0, 0);
            }

render = () => {

return (
<article className="homePage flexProjects marginHeader bodyCopy poppinsLight">
    <header className="headerHome headerPolicy">
        <h2 className="h2 poppinsBold marginH2">Privacy- & cookiepolicy</h2>
    </header>
    <div className="policyAlignment">

    
                            <h5 className="h5"><strong>1. Cookies</strong></h5><br/>
                            <p >
                                Cookies zijn kleine data- of tekstbestanden
                                    die door websites en applicaties op uw lokale computer worden geplaatst. Dergelijke
                                    cookies kunnen verschillende doeleinden hebben: er zijn technische cookies
                                    (bijvoorbeeld voor het bijhouden van taalinstellingen), sessiecookies (tijdelijke
                                    cookies die verlopen na één sessie) en tracking cookies (cookies die uw gedrag op
                                    het internet volgen en bijhouden, om u op die manier een meer optimale
                                    gebruikservaring te kunnen aanbieden).<br/>
                                    <br/>
                                    De Belgische Wet betreffende de elektronische
                                    communicatie van 13 juni 2005 bevat enkele bepalingen rond cookies en het gebruik
                                    ervan op websites. De wet is een omzetting van de Europese e-Privacyrichtlijn, wat
                                    betekent dat de cookiewetgeving verschillend kan geïmplementeerd worden in andere
                                    Europese BV lidstaten. </p><br/>
                            <p>BV Christl Dekimpe is gevestigd in België en volgt bijgevolg de Belgische wetgeving inzake
                                    cookies.</p><br/>
                            <h5 className="h5" ><strong>2.</strong><strong> Doel en nut van cookies</strong>
                            </h5><br/>
                            <p>BV Christl Dekimpe wil
                                    elke bezoeker van de website zo goed mogelijk informeren over zijn rechten onder de
                                    Belgische wetgeving inzake cookies, en over welke cookies BV Christl Dekimpe<br/>
                                    gebruikt. Door de website te gebruiken gaat de bezoeker akkoord met het gebruik van
                                    cookies. Cookies helpen <br/>BV
                                    Christl Dekimpe<br/> om uw bezoek aan e website te
                                    optimaliseren, om technische keuzes te herinneren (bijvoorbeeld) een taalkeuze, een
                                    nieuwsbrief, et cetera) en om u meer relevante diensten en aanbiedingen te tonen.
                                </p><br/>
                            <p>Als u de website van BV Christl Dekimpe wil consulteren, is het
                                    aan te raden dat de technische instellingen voor cookies ingeschakeld werden. Zonder
                                    ingeschakelde cookies kan <br/>BV Christl Dekimpe
                                geen probleemloos bezoek op de website garanderen.
                                <br/>Indien u de cookies liever niet wenst, bent u als
                                    bezoeker vrij om de cookies uit te schakelen. </p><br/>
                            <p>Wij gebruiken cookies om uw bezoek aan onze website te
                                    verbeteren. De cookies die wij gebruiken zijn veilig. De informatie die we
                                    verzamelen met behulp van cookies helpt ons om eventuele fouten te identificeren of
                                    om u specifieke diensten te laten zien waarvan wij denken dat ze voor u van belang
                                    kunnen zijn. </p><br/>
                            <h5 className="h5" ><strong>3. Soorten cookies
                                        gebruikt door BV Christl Dekimpe</strong></h5><br/>
                            <p>We onderscheiden volgende types cookies, naargelang hun
                                    doeleinden:</p><br/>
                            <ul>
                                <li ><b>Essentiële/ Strikt noodzakelijke cookies: </b></li>
                            </ul>
                            <p>Deze cookies zijn nodig om onze website te laten
                                    functioneren en kunnen niet worden uitgeschakeld in onze systemen. Ze worden meestal
                                    alleen ingesteld als reactie op acties die door u zijn gesteld, zoals het instellen
                                    van uw privacyvoorkeuren, inloggen of het invullen van formulieren. Ze zijn
                                    noodzakelijk voor een goede communicatie en ze vergemakkelijken het navigeren
                                    (bijvoorbeeld naar een vorige pagina terugkeren, etc.).</p>
                            < ul className="ulPolicy">
                                <li ><b>Niet-essentiële cookies: </b></li>
                            </ul>
                            <p>Deze cookies zijn op zich niet noodzakelijk om de website
                                    te laten functioneren, maar ze helpen ons wel een verbeterde en gepersonaliseerde
                                    website aan te bieden.</p>
                            < ul className="ulPolicy">
                                <li><b>Functionele cookies:</b></li>
                            </ul>
                            <p>Deze cookies stellen onze website in staat om verbeterde
                                    functionaliteit en personalisatie te bieden. Ze kunnen worden ingesteld door ons of
                                    door externe providers wiens diensten we hebben toegevoegd aan onze pagina’s.
                            </p>
                                <ul className="ulPolicy">
                                        <li>session</li>
                                        <li>mkjs_group_id</li>
                                        <li>_ga</li>
                                        <li>_mkto_trk</li>
                                        <li>CookieConsent</li>
                                        <li>ajs_anonymous_id</li>
                                        <li>mkjs_user_id</li>
                                        <li>_ga</li>
                                        <li>ajs_user_id</li>
                                </ul>
                            < ul className="ulPolicy">
                                <li><b>Analytische cookies:</b></li>
                            </ul>
                            <p>Met deze cookies kunnen we bezoeken en traffic bijhouden,
                                    zodat we de prestaties van de website kunnen meten en verbeteren. Ze helpen ons te
                                    weten welke pagina’s het meest en het minst populair zijn en hoe bezoekers zich door
                                    de website verplaatsen.</p>
                            < ul className="ulPolicy">
                                <li><b>Targeting / advertising cookies:</b></li>
                            </ul>
                            <p>Deze cookies kunnen door onze advertentiepartners via de
                                    website worden ingesteld. Ze kunnen door die bedrijven worden gebruikt om een
                                    profiel van uw interesses samen te stellen en u relevante advertenties op andere
                                    sites te laten zien.</p>
                            <p>Wij gebruiken enerzijds onze eigen cookies en anderzijds
                                    cookies van zorgvuldig geselecteerde partners met wie we samenwerken en die onze
                                    diensten op hun website adverteren. </p>
                            <p>Neem kennis van ons <b>Privacyverklaring</b> voor meer informatie omtrent de verwerking van
                                    persoonsgegevens door BV Christl Dekimpe.
                            </p><br/>
                            <h5 className="h5" ><strong>4. Beheer van de
                                        cookies</strong></h5><br/>
                            <p>Zorg ervoor dat cookies zijn ingeschakeld in uw browser.
                                    Om cookies in te schakelen moeten de volgende handelingen uitgevoerd worden:
                            </p>
                            <h6 ><b>Bij browser – Microsoft Internet Explorer</b></h6>
                            < ol className="ulPolicy">
                                <li >In Internet Explorer, klik
                                        op ‘Internetopties’ in het menu ‘Extra’.</li>
                                <li >Op het tabblad ‘Privacy’,
                                        verplaats de instellingen- schuifknop naar ‘laag’ of ‘accepteer alle cookies’
                                        (instelling boven ‘medium’ schakelt cookies uit).</li>
                                <li >Klik op
                                                ‘OK’.</li>
                            </ol>
                            <h6 ><b>Bij browser – Mozilla Firefox</b></h6>
                            < ol className="ulPolicy">
                                <li >Klik op ‘Firefox’ in de
                                        linkerbovenhoek van uw browser en klik vervolgens op ‘Opties’.</li>
                                <li >Op het tabblad ‘Privacy’,
                                        zorg ervoor dat de ‘Websites laten weten dat ik niet gevolgd wil worden’ niet is
                                        aangevinkt.</li>
                                <li >Klik op
                                                ‘OK’.</li>
                            </ol>
                            <h6 ><b>Bij browser – Google Chrome</b></h6>
                            < ol className="ulPolicy">
                                <li >Klik op de drie puntjes
                                        naast de browserbalk bovenaan in uw browservenster en kies ‘Opties’.</li>
                                <li >Zoek het gedeelte ‘Privacy
                                        and security’ en klik op ‘content settings’.</li>
                                <li >Klik op de optie
                                        ‘cookies’.</li>
                                <li >Selecteer nu
                                                ‘Allow sites to save and read cookie data’.</li>
                            </ol>
                            <h6 ><b>Bij browser – Safari</b></h6>
                            < ol className="ulPolicy">
                                <li >Kies ‘Voorkeuren’ in het
                                        taakmenu. (Het taakmenu bevindt zich rechtsboven in het Safari-venster en ziet
                                        eruit als een tandwiel of klik op ‘Safari’ in het uitgebreide taakmenu.)
                                </li>
                                <li >Klik op het Privacy
                                        tabblad. Zoek de sectie genaamd ‘Cookies en andere websitegegevens’</li>
                                <li >Duid aan dat u
                                                cookies aanvaardt.</li>
                            </ol>
                            <p>Als u de website van BV Christl Dekimpe wil consulteren, is het
                                    aan te raden dat u cookies ingeschakeld hebt. Echter, als u dit liever niet wenst,
                                    bent u als bezoeker vrij om de cookies uit te schakelen via uw browserinstellingen.
                                    Dit kan via volgende manieren:</p>
                            <h6 ><b>Bij browser – Microsoft Internet Explorer</b></h6>
                            < ol className="ulPolicy">
                                <li >Selecteer in Internet
                                        Explorer de knop Extra en selecteer Internetopties.</li>
                                <li >Selecteer het
                                                tabblad Privacy en verplaats onder Instellingen de schuifregelaar naar
                                                boven om alle cookies te blokkeren. Klik op OK.
                                </li>
                            </ol>
                            <h6 ><b>Bij browser – Mozilla Firefox</b></h6>
                            < ol className="ulPolicy">
                                <li >Klik op de menuknop en
                                        kies ‘Voorkeuren’.</li>
                                <li >Selecteer het paneel
                                        ‘Privacy &amp; Beveiliging’ en ga naar de sectie ‘Geschiedenis’.</li>
                                <li >Stel naast ‘Firefox zal’
                                        in op ‘Aangepaste instellingen gebruiken voor geschiedenis’.</li>
                                <li >Stel&nbsp;‘Cookies van
                                        derden accepteren’&nbsp;in op&nbsp;‘Nooit’.</li>
                                <li >Sluit de pagina&nbsp;‘about: preferences’.
                                            Wijzigingen die u hebt aangebracht, worden automatisch
                                            opgeslagen.</li>
                            </ol>
                            <h6 ><b>Bij browser – Google Chrome</b></h6>
                            < ol className="ulPolicy">
                                <li >Selecteer ‘Meer
                                        Instellingen’ in de browserwerkbalk.</li>
                                <li >Selecteer onderaan de
                                        pagina de optie ‘Geavanceerd’.</li>
                                <li >Selecteer bij ‘Privacy en
                                        beveiliging’ de optie ‘Instellingen voor content’.</li>
                                <li >Selecteer
                                        ‘Cookies’.</li>
                                <li >Schakel ‘Sites toestaan cookiegegevens op te slaan
                                            en te lezen’ uit.</li>
                            </ ol>
                            <h6 ><b>Bij browser – Safari</b></h6>
                            < ol className="ulPolicy">
                                <li >Kies ‘Voorkeuren’ in het
                                        taakmenu. (Het taakmenu bevindt zich rechtsboven in het Safari-venster en ziet
                                        eruit als een tandwiel of klik op ‘Safari’ in het uitgebreide taakmenu.)
                                </li>
                                <li >Klik op het Privacy
                                        tabblad. Zoek de sectie genaamd ‘Cookies en andere websitegegevens’</li>
                                <li >Duid aan dat u cookies
                                        niet aanvaardt.</li>
                            </ol>
                            <p>Of raadpleeg hiervoor de help-functie van uw
                                    internetbrowser.</p><br/>
                            <h5 className="h5"><b><strong>5. </strong>Rechten van de
                                    bezoekers</b></h5><br/>
                            <p>Aangezien cookies een verwerking van persoonsgegevens
                                    kunnen uitmaken, heeft u als betrokkene recht op de rechtmatige en veilige
                                    verwerking van de persoonsgegevens. Als betrokkene kan u volgende rechten
                                    uitoefenen:</p>
                            < ul className="ulPolicy">
                                <li >Recht op verzet: Indien er sprake is van een
                                            zwaarwegende en gerechtvaardigde redenen kan men zich verzetten tegen de
                                            verwerking van persoonsgegevens.</li>
                                <li >Recht op toegang:
                                    Iedere
                                            betrokkene die zijn identiteit bewijst, beschikt over een recht op toegang
                                            tot de informatie rond het al dan niet bestaan van verwerkingen van zijn
                                            persoonsgegevens, net als de doeleinden van deze verwerking, de categorieën
                                            van gegevens waarop deze verwerkingen betrekking hebben en de categorieën
                                            van ontvangers aan wie de gegevens worden verstrekt.</li>
                                <li >Recht op verbetering:
                                    Onnauwkeurige of onvolledige persoonsgegevens
                                        kunnen op verzoek van de betrokkene steeds verbeterd of zelfs uitgewist
                                        worden.</li>
                            </ul>
                            <p>De uitoefening van deze rechten gebeurt conform de
                                    modaliteiten zoals bepaald in onze <b>Privacyverklaring</b>. Meer informatie
                                    over de rechten van bezoekers vindt u ook in de <b>Privacyverklaring</b>. Mocht u na het
                                    lezen van deze Cookieverklaring toch nog vragen of opmerkingen rond cookies hebben,
                                    kan u steeds contact opnemen via <a href="mailto:info@dekimpe-nv.be" className="blackColor">info@dekimpe-nv.be</a>.</p>
    </div>
</article>

)
}
};

export default Policy;