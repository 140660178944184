import React from "react";
import '../css/index.css';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Notfound extends React.PureComponent {

    render = () => {
        const { t } = this.props;
        return (
            <section className="flexProjects homePage">
                <h2 className="h2 poppinsMedium noAfter">Ooops...</h2>
                <p className="bodyCopy text-center updateCopyCollecties poppinsLight">{t("notfound")}</p>
                <p className="poppinsMedium headerButton updateCopyCollecties updateButtonHeader marginBottomElements blackColor"><Link to="/Home" className="blackColor">{t("homepageBack")}</Link></p>
            </section>
        )
    }
};

export default withTranslation()(Notfound);