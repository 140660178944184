import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import LanguageSelector from './dropdown/LanguageSelector';

import logo from "../assets/logoZwart.png";
import { FaAlignRight } from 'react-icons/fa'
import {FaPinterest} from 'react-icons/fa';
import {FaFacebook} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import logoWit from "../assets/logo.png";

import SiteSelector from "./dropdown/SiteSelector";

class Navigation extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            toggleMenu: false,
            keuze: props.keuze,
            navColor: props.navColor,
            lng: this.props.taal
        }
    }

    handleClick = e => {
        e.preventDefault();
        this.setState({ toggleMenu: !this.state.toggleMenu });
        const liElements = document.querySelectorAll('.listNavigation a');
        liElements.forEach(element => element.classList.toggle('hiddenNavigation'));
    }

    closeMenu() {
        this.setState({ toggleMenu: false });
        this.props.setNavColor('white');
    }

    componentDidMount() {
        let tijdelijkeKeuze = sessionStorage.getItem('keuze');
        this.setState({ keuze: tijdelijkeKeuze });
    }

    handleNavColor = () => {
        this.props.setNavColor('black');
    }

    extendDropdown(e){
        if(this.state.toggleMenu === true){
            e.preventDefault();
        }
    }

    handleClickInterior = value => {
        this.props.setCollectie(value);
        this.props.history.push("/Collecties");
    }

    scrollToView(el){
        var bodyRect = document.body.getBoundingClientRect(),
        elemRect = el.getBoundingClientRect(),
        offset   = elemRect.top - bodyRect.top;
        window.scrollTo({ top:offset-110, behavior: 'smooth' });
    }

    renderSwitch(keuze, t, navElementClass, navColor, linksColorClass, borderColor, page) {
        console.log(this.state);
        switch(keuze) {
            case "interior":
                return  <ul className={`updateNavigation ${this.state.toggleMenu ? "navigation  " : "navigation"} ${navColor === "black" ? "whiteButton" : ""} ${this.state.toggleMenu ? " poppinsLight" : "poppinsLight"}`}>
                       
                <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`}  onClick={() => {this.closeMenu(); this.handleClickInterior(2);}}>
                {t("barstoelen")}
                </li>
                {/* <NavLink to="/destinea" className={linksColorClass}>
                <li className={` updateDestineaHeader category paddingManallagi updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleNavColor(); this.handleClickInterior(3);}}>
                {t("Destinea")} <span className="tradeMarkHeader poppins poppinsLight">&reg;</span>
                </li>
          </NavLink>
             */}
              
          
            
                <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`}  onClick={() => {this.closeMenu(); this.handleClickInterior(4);}}>
                {t("eettafels")}
                </li>
            
           
                <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`}  onClick={() => {this.closeMenu(); this.handleClickInterior(5);}}>
                {t("kasten")}
                </li>
          
            
                <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`}  onClick={() => {this.closeMenu(); this.handleClickInterior(6);}}>
                {t("loungezetels")}
                </li>
           
                <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(7);}}>
                {t("salontafels")}
                </li>
           
                <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(8);}}>
                {t("sofas")}
                </li>
            
           
                <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`}  onClick={() => {this.closeMenu(); this.handleClickInterior(9);}}>
                {t("stoelen")}
                </li>
           
            
    </ul> ;
    case "projects":
        return   <ul className={`updateNavigation ${this.state.toggleMenu ? "navigation  " : "navigation"} ${navColor === "black" ? "whiteButton" : ""} ${this.state.toggleMenu ? " poppinsLight" : "poppinsLight"}`}>
        <NavLink to="/Barstoelen" className={linksColorClass}>
              <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => this.closeMenu()}>
              {t("barstoelen")}
              </li>
          </NavLink>
          {/* <NavLink to="/projects/destinea" className={linksColorClass}>
              <li className={` updateDestineaHeader category paddingManallagi updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav "} ${ page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleNavColor()}}>
              {t("Destinea")}  <span className="tradeMarkHeader poppins poppinsLight">&reg;</span>
              </li>
          </NavLink> */}
          <NavLink to="/Loungezetel" className={linksColorClass}>
              <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => this.closeMenu()}>
              {t("loungezetels")}
              </li>
          </NavLink>
          <NavLink to="/Stoelen" className={linksColorClass}>
              <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`}onClick={() => this.closeMenu()}>
              {t("stoelen")}
              </li>
          </NavLink>
        <NavLink to="/Tafels" className={linksColorClass}>
              <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => this.closeMenu()}>
              {t("barstoelen Projects")}
              </li>
          </NavLink>
          
          
          
          
  </ul>;
  case "outdoor":
      return <ul className={`updateNavigation ${this.state.toggleMenu ? "navigation  " : "navigation"} ${navColor === "black" ? "whiteButton" : ""} ${this.state.toggleMenu ? " poppinsLight" : "poppinsLight"}`}>
                       
      <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(121);}}>
      {t("eettafels")}
      </li>
 
  
      <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(122);}}>
      {t("salontafels")}
      </li>

  
      <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(123);}}>
      {t("stoelen")}
      </li>
  
 
      <li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(124);}}>
      {t("barstoelen Projects")}
      </li>
 
  
</ul>;
default: 
return  <ul className={`updateNavigation ${this.state.toggleMenu ? "navigation  " : "navigation"} ${navColor === "black" ? "whiteButton" : ""} ${this.state.toggleMenu ? " poppinsLight" : "poppinsLight"}`}>
                       
<li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(2);}}>
{t("barstoelen")}
</li>


{/* <li className={` updateDestineaHeader category paddingManallagi updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav hiddenNavigation"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleNavColor(); this.handleClickInterior(3);}}>
{t("Destinea")} <span className="tradeMarkHeader poppins poppinsLight">&reg;</span>
</li> */}


<li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(4);}}>
{t("eettafels")}
</li>


<li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(5);}}>
{t("kasten")}
</li>


<li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(6);}}>
{t("loungezetels")}
</li>

<li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(7);}}>
{t("salontafels")}
</li>

<li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(8);}}>
{t("sofas")}
</li>


<li className={`  category  updateNavElement  ${this.state.toggleMenu ? "elementNav" : "elementNav"} ${page === "/Destinea" || navColor === "black" ? "whiteBorder" : ""}`} onClick={() => {this.closeMenu(); this.handleClickInterior(9);}}>
{t("stoelen")}
</li>


</ul> ;
        }
    }

    render = () => {
        const { page, navColor, lng} = this.props;
        const { keuze } = this.props
        const { t } = this.props;
        const navElementClass = this.state.toggleMenu ? "elementNav " : "elementNav hiddenNavigation";
        const linksColorClass = page === "/Destinea" || navColor === "black" ? "links white" : "links blackColor";
        const borderColor = page === "/Destinea" || navColor === "black" ? "whiteBorder" : "";

        return (
            <header className={page === "/Destinea" || navColor === "black" ? "header black" : "header"}>
             <Link to="/Home"><img src={page === "/Destinea" || navColor === "black" ? logoWit : logo} alt="" width="4202" height="867" className="logoHeader" />
             </Link>
             
                <div className="flexNeed">
                    
                    <div className="mainNav">
                    
                    
                    <nav className={this.state.toggleMenu ? "nav poppinsLight" : "poppinsLight navMargin"}>
                        
                        <button className={`${this.state.toggleMenu ? "responsiveMenu responsiveMenuGold" : "responsiveMenu"} ${navColor === "black" ? "whiteButton" : "responsiveMenu"}`} onClick={e => this.handleClick(e)}>
                            <FaAlignRight className={navColor === "black" ? "fill" : ""}/>
                        </button>
                        {
                            keuze === "interior" || keuze === "outdoor" ?
                                /* -- Interior pagina -- */
                                <ul className={`${this.state.toggleMenu ? "navigation open " : "navigation"} ${navColor === "black" ? "whiteButton" : ""}`}>
                                    
                                    <NavLink  to={{pathname: "/OverOns"}}  className={linksColorClass} >
                                        <li className={navElementClass} onClick={() => this.closeMenu()}>
                                        {t("Over ons")}
                                        </li>
                                    </NavLink>
                                    <NavLink to={{pathname: "/Nieuws"}}  className={linksColorClass} >
                                        <li className={navElementClass} onClick={() => this.closeMenu()}>
                                        {t("Nieuws")}
                                        </li>
                                    </NavLink>
                                   
                                    <NavLink  to="/Contact" className={linksColorClass}>
                                        <li className={[navElementClass, "contactNav"].join(' ')} onClick={() => this.closeMenu()}>
                                        {t("Contact")}
                                        </li>
                                    </NavLink>
                                    
                                </ul>
                                /* -- Project pagina -- */
                                : <ul className={`${this.state.toggleMenu ? "navigation open " : "navigation"} ${navColor === "black" ? "whiteButton" : ""}`}>
                                    
                                    <NavLink  to={{pathname: "/OverOns"}}  className={linksColorClass} >
                                        <li className={navElementClass} onClick={() => this.closeMenu()}>
                                        {t("Over ons")}
                                        </li>
                                    </NavLink>
                                    <NavLink  to={{pathname: "/creatiefadvies"}}  className={linksColorClass} >
                                        <li className={navElementClass} onClick={() => this.closeMenu()}>
                                        {t("creatiefAdvies")}
                                        </li>
                                    </NavLink>
                                    <NavLink to={{pathname: "/Nieuws"}}  className={linksColorClass} >
                                        <li className={navElementClass} onClick={() => this.closeMenu()}>
                                        {t("Nieuws")}
                                        </li>
                                    </NavLink>
                                  
                                    <NavLink to="/Contact" className={linksColorClass}>
                                        <li className={[navElementClass, "contactNav"].join(' ')} onClick={() => this.closeMenu()}>
                                        {t("Contact")}
                                        </li>
                                    </NavLink>
                                    
                                </ul>}
                                </nav>
                    <ul className="navigation socials">
                        <li className={navElementClass}>
                          <a href="https://www.facebook.com/christldekimpe.be/"  target="_blank" rel="noopener noreferrer">  <FaFacebook className={`iconHeader ${page === "/Destinea" || navColor === "black" ? "fill" : "fillBlack"}`}></FaFacebook></a>
                        </li>
                        <li className={navElementClass}>
                           <a href="https://www.instagram.com/christldekimpe/" target="_blank" rel="noopener noreferrer"><FaInstagram className={`iconHeader ${page === "/Destinea" || navColor === "black" ? "fill" : "fillBlack"}`}></FaInstagram></a> 
                        </li>
                        <li className={navElementClass}>
                            <a href="https://www.pinterest.com/Christldekimpe/" target="_blank" rel="noopener noreferrer"><FaPinterest className={`iconHeader ${page === "/Destinea" || navColor === "black" ? "fill" : "fillBlack"}`}></FaPinterest></a>
                        </li>
                        <li className={navElementClass}><LanguageSelector taal={lng} handleTaal={this.props.handleLanguage} kleur={navColor} page={page}/></li>
                       
                    </ul>
                    </div>
                    {this.renderSwitch(keuze, t, navElementClass, navColor, linksColorClass, borderColor, page)}
                    <SiteSelector history={this.props.history} onMadeChoise={this.props.onMadeChoise} page={this.props.page} keuze={this.props.keuze} navColor={this.props.navColor} setNavColor={this.props.setNavColor}/>
                </div>
                
            </header>

        )
    }
};

export default (withTranslation()(Navigation));