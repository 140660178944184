import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import "../css/index.css";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";

class AlgemeneVoorwaarden extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lng: this.props.taal,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.hash);
  }

  render = () => {
    const { t } = this.props;
    return (
      <article className="homePage flexProjects marginHeader bodyCopy poppinsLight">
        <header className="headerHome headerPolicy">
          <h2 className="h2 poppinsBold marginH2">
            {t("algemenevoorwaarden")}
          </h2>
        </header>
        <br /><br />
        <div className="policyAlignment">
            <ul type="1">
              <li>1. {t("voorwaarde1")}</li>
              <br />
              <li>2. {t("voorwaarde2")}</li>
              <br />
              <li>3. {t("voorwaarde3")}</li>
              <br />
              <li>4. {t("voorwaarde4")}</li>
              <br />
              <li>5. {t("voorwaarde5")}</li>
              <br />
              <li>6. {t("voorwaarde6")}</li>
              <br />
              <li>7. {t("voorwaarde7")}</li>
            </ul>
          <br />
          <br />
          <Link
            to="/contact"
            className="poppinsMedium headerButton updateButtonHeader marginBottomElements blackColor bodyCopy knopHover knopHover-contact__page"
          >
            {t("Contacteer ons")}
          </Link>
        </div>
      </article>
    );
  };
}

export default withTranslation()(AlgemeneVoorwaarden);
