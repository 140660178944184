import React, { Component, Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import "./css/index.css";
import Sticky from "react-stickynode";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import SimpleReactLightbox from "simple-react-lightbox";
import ScrollManager from "./ScrollManager";

import Navigation from "./components/Navigation";
import LoadingScreen from "./components/LoadingScreen";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Notfound from "./components/Notfound";

import Policy from "./components/Policy";
import AlgemeneVoorwaarden from "./components/AlgemeneVoorwaarden";

const KeuzeTraject = lazy(() => import("./components/KeuzeTraject"));
const OverOns = lazy(() => import("./components/OverOns"));
const CreatiefAdvies = lazy(() => import("./components/CreatiefAdvies"));
const Collecties = lazy(() => import("./components/Collecties"));
// const Destinea = lazy(() => import('./components/Destinea'));
// const DestineaProjects = lazy(() => import('./components/DestineaProjects'));
const Home = lazy(() => import("./components/Home"));
const Nieuws = lazy(() => import("./components/Nieuws"));
const Stoelen = lazy(() => import("./components/Stoelen"));
const Tafels = lazy(() => import("./components/Tafels"));
const Barstoelen = lazy(() => import("./components/Barstoelen"));
const Loungezetels = lazy(() => import("./components/Loungezetel"));
const Products = lazy(() => import("./components/Products"));
const SpeciaalGrid2 = lazy(() => import("./components/grid/SpeciaalGrid2"));
const NormaalGrid2 = lazy(() => import("./components/grid/NormaalGrid2"));
const EnkelGrid = lazy(() => import("./components/grid/EnkelGrid"));
const Contact = lazy(() => import("./components/Contact"));
const Beurs = lazy(() => import("./components/Beurs"));

const API_PATH = "https://christldekimpe.be/api/index.php";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keuze: "interior",
      loading: true,
      nee: "",
      currentPage: 1,
      nieuwsID: "",
      navColor: "white",
      collectie: 2,
      gekozenTag: "",
      gekozenCategory: "",
      lng: "nl",
      list: [],
    };
    this.props = props;
    // Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
    ReactGA.initialize("UA-159940625-9");
    // This just needs to be called once since we have no routes in this case.
  }

  componentDidMount() {
    console.log("test");
    if (
      this.props.location.pathname === "/destinea" ||
      this.props.location.pathname === "/projects/destinea"
    ) {
      this.setState({ navColor: "black" });
    }
    this.setState({ keuze: sessionStorage.getItem("keuze") });
    setTimeout(() => this.setState({ loading: false }), 1500); // simulates an async action, and hides the spinner
    ReactGA.pageview(window.location.hash);
  }

  handleMadeChoise = (value) => {
    sessionStorage.setItem("keuze", value);
    this.setState({ keuze: value });
    this.props.history.push(`/Home`);
  };

  handleMadeChoiseCollectie = (value) => {
    this.setState({ collectie: value });
    this.props.history.push(`/Collecties`);
  };

  handleClickNieuws = (slug) => {
    this.setState({ nieuwsID: slug });
  };

  handleClickChange = (tekst) => {
    this.setState({ keuze: tekst });
  };

  setNavColor = (color) => {
    this.setState({ navColor: color });
  };

  handleSetTag = (tag) => {
    this.setState({ gekozenTag: tag });
  };

  handleSetCategory = (category) => {
    this.setState({ gekozenCategory: category });
  };

  setCollectie = (collectie) => {
    if (collectie === 3) {
      this.setNavColor("black");
    }
    this.setState({ collectie: collectie });
  };

  handleChange = (event) => {
    let newlang = event;
    this.setState((prevState) => ({ lng: newlang }));
    this.props.i18n.changeLanguage(newlang);
  };

  render() {
    const {
      keuze,
      nee,
      currentPage,
      nieuwsID,
      loading,
      navColor,
      collectie,
      gekozenTag,
      lng,
      gekozenCategory,
      list,
    } = this.state;
    const { history } = this.props;
    const { t } = this.props;
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <SimpleReactLightbox>
        <div className="App">
          <main>
            <ScrollManager scrollKey="some-screen-key" />
            <Sticky enabled={true} bottomBoundary={3000} innerZ={900}>
              {this.props.match.isExact ? (
                ""
              ) : (
                <Navigation
                  setCollectie={this.setCollectie}
                  collectie={collectie}
                  history={this.props.history}
                  onMadeChoise={this.handleMadeChoise}
                  keuze={keuze}
                  setNavColor={this.setNavColor}
                  navColor={navColor}
                  page={history.location.pathname}
                  handleChangeProject={this.handleClickChange}
                  taal={lng}
                  handleLanguage={this.handleChange}
                />
              )}
            </Sticky>
            <ScrollToTop navColor={navColor} />
            <SimpleReactLightbox>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={() => (
                      <KeuzeTraject
                        keuze={keuze}
                        history={history}
                        onMadeChoise={this.handleMadeChoise}
                      />
                    )}
                  />
                  <Route
                    path="/Overons"
                    exact
                    render={() => <OverOns keuze={keuze} />}
                  />
                  <Route
                    path="/home"
                    exact
                    render={() => (
                      <Home
                        setCollectie={this.setCollectie}
                        history={this.props.history}
                        keuze={keuze}
                        handleClick={this.handleClickNieuws}
                        onMadeChoiseCollectie={this.handleMadeChoiseCollectie}
                        taal={lng}
                      />
                    )}
                  />
                  <Route
                    path="/nieuws"
                    exact
                    render={() => (
                      <Nieuws
                        setCollectie={this.setCollectie}
                        history={this.props.history}
                        keuze={keuze}
                        handleClick={this.handleClickNieuws}
                        onMadeChoiseCollectie={this.handleMadeChoiseCollectie}
                        taal={lng}
                      />
                    )}
                  />
                  <Route
                    path="/collecties/"
                    exact
                    onLeave={() => this.setNavColor("white")}
                    render={({ match }) => (
                      <Collecties
                        collectie={collectie}
                        setCollectie={this.setCollectie}
                        keuze={keuze}
                        currentPage={currentPage}
                        setNavColor={this.setNavColor}
                        handleClick={this.handleClickNieuws}
                        handleTag={this.handleSetTag}
                        handleCategory={this.handleSetCategory}
                        history={this.props.history}
                        onMadeChoiseCollectie={this.handleMadeChoiseCollectie}
                        test={match}
                        list={list}
                      />
                    )}
                  />
                  <Route
                    path="/contact"
                    exact
                    render={() => <Contact api={API_PATH} />}
                  />
                  {/* <Route path='/destinea' exact render={() => <Destinea keuze={keuze} nee={nee} handleClick={this.handleClickNieuws}/> } />  */}
                  {/* <Route path='/projects/destinea' exact render={() => <DestineaProjects keuze={keuze} nee={nee} handleClick={this.handleClickNieuws}/> } />    */}
                  <Route
                    path="/grid1"
                    exact
                    render={() => (
                      <SpeciaalGrid2
                        keuze={keuze}
                        handleClick={this.handleClickNieuws}
                        handleTag={this.handleSetTag}
                        handleCategory={this.handleSetCategory}
                        history={this.props.history}
                        onMadeChoiseCollectie={this.handleMadeChoiseCollectie}
                        taal={lng}
                      />
                    )}
                  />
                  <Route
                    path="/grid2"
                    exact
                    render={() => (
                      <NormaalGrid2
                        keuze={keuze}
                        handleClick={this.handleClickNieuws}
                        handleTag={this.handleSetTag}
                        handleCategory={this.handleSetCategory}
                        history={this.props.history}
                        onMadeChoiseCollectie={this.handleMadeChoiseCollectie}
                        taal={lng}
                      />
                    )}
                  />
                  <Route
                    path="/grid3"
                    exact
                    render={() => (
                      <EnkelGrid
                        keuze={keuze}
                        handleClick={this.handleClickNieuws}
                        handleTag={this.handleSetTag}
                        handleCategory={this.handleSetCategory}
                        history={this.props.history}
                        onMadeChoiseCollectie={this.handleMadeChoiseCollectie}
                        taal={lng}
                      />
                    )}
                  />
                  <Route path="/tafels" exact render={() => <Tafels />} />
                  <Route
                    path="/barstoelen"
                    exact
                    render={() => <Barstoelen />}
                  />
                  <Route path="/stoelen" exact render={() => <Stoelen />} />
                  <Route
                    path="/loungezetel"
                    exact
                    render={() => <Loungezetels />}
                  />
                  <Route
                    path="/cookieprivacypolicy"
                    exact
                    render={() => <Policy />}
                  />
                  <Route
                    path="/creatiefAdvies"
                    exact
                    render={() => <CreatiefAdvies />}
                  />
                  <Route
                    path="/AlgemeneVoorwaarden"
                    exact
                    render={() => <AlgemeneVoorwaarden />}
                  />
                  <Route
                    path="/beurs/:slug"
                    exact
                    render={() => (
                      <Beurs nieuwsID={nieuwsID} history={history} />
                    )}
                  />
                  <Route
                    path="/products/:collectie/:slug"
                    exact
                    render={({ match }) => (
                      <Products
                        nieuwsID={nieuwsID}
                        tag={gekozenTag}
                        category={gekozenCategory}
                        history={this.props.history}
                        collectie={collectie}
                        onMadeChoiseCollectie={this.handleMadeChoiseCollectie}
                        taal={lng}
                        test={match}
                      />
                    )}
                  />

                  <Route component={Notfound} />
                </Switch>
              </Suspense>
            </SimpleReactLightbox>
            <CookieConsent
              buttonText={"Cookies accepteren"}
              declineButtonText={"Cookies niet aanvaarden"}
              contentClasses="bodyCopy poppinsLight"
              containerClasses="fullWidth"
            >
              {t("cookiepolicy")}
              <Link to={"/cookieprivacypolicy"} className="white">
                {t("meer informatie")}
              </Link>
            </CookieConsent>
            {this.props.match.isExact ? (
              ""
            ) : (
              <Footer
                keuze={keuze}
                setCollectie={this.setCollectie}
                history={this.props.history}
                setNavColor={this.setNavColor}
                page={history.location.pathname}
              />
            )}
          </main>
        </div>
      </SimpleReactLightbox>
    );
  }
}

export default withRouter(withTranslation()(App));
