import React from "react";
import { IoIosArrowDropupCircle } from "react-icons/io";

class ScrollToTop extends React.PureComponent {


    constructor(props) {
        super(props);
        this.state = {
          is_visible: false,
          navColor: this.props.navColor
        };
      }

      componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function(e) {
          scrollComponent.toggleVisibility();
        });

        this.setState({navColor: this.props.navColor});
      }
  
      toggleVisibility() {
        if (window.pageYOffset > 400) {
          this.setState({
            is_visible: true
          });
        } else {
          this.setState({
            is_visible: false
          });
        }
      }

      componentDidUpdate(prevProps) {
        if(prevProps.navColor !== this.props.navColor) {
          this.setState({navColor: this.props.navColor});
        }
      }
  
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

      render() {
        const { is_visible, navColor } = this.state;
        return (
          <div className="scroll-to-top">
            {is_visible && (
              <div onClick={() => this.scrollToTop()} className="backToTop">
                  <IoIosArrowDropupCircle className={`iconScroll ${navColor === "black" ? "fill" : ""}`}/>
              </div>
            )}
          </div>
        );
      }
};

export default ScrollToTop;